
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'

@Component({
  components: { UploadFile },
  filters: {
    previewList: function (value: FileInfo[]) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})

export default class HandoverMatterDetail extends Vue {
  private info = {
    longitude: '', // 打点经纬度
    latitude: ''
  }

  private map: AMap.Map | any = null

  get issueId () {
    return this.$route.params.issueId
  }

  created () {
    this.getData()
  }

  destroyed () {
    this.map && this.map.destroy()
  }

  // 渲染地图
  loadMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0'
    }).then(() => {
      const lnglat: any = [Number(this.info.longitude), Number(this.info.latitude)]
      const map = new AMap.Map('map', {
        zoom: 14,
        center: lnglat
      })
      const marker: any = new AMap.Marker({
        icon: new AMap.Icon({
          // 图标的取图地址
          image: require('@/assets/icon/handover/map_dot.png'),
          // 图标尺寸
          size: new AMap.Size(25, 35),
          imageSize: new AMap.Size(25, 35)
        }),
        offset: new AMap.Pixel(-12, -47),
        position: new AMap.LngLat(lnglat[0], lnglat[1])
      })
      map.add(marker)
      this.map = map
    })
  }

  getData () {
    this.$axios.get(this.$apis.handover.selectTransferIssueByIssueId, {
      issueId: this.issueId
    }).then((res) => {
      if (res) {
        this.info = res
        if (this.info.longitude) {
          this.loadMap()
        }
      }
    })
  }
}
